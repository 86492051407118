



































































































































































































































#productionBase {
  height: 100vh;
  overflow-y: scroll;
  background-color: #f8fafd;

  .container {
    width: 1168px;
    margin: 0 auto;
    padding: 60px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }
    .text-1 {
      width: 1168px;
      text-align: center;
      font-size: 17px;
      margin-top: 16px;
      color: #5c6b84;
      padding: 0 75px;
    }
    .container_b {
      width: 100%;
      overflow: hidden;
      margin-top: 40px;
      .l {
        width: 802px;
        height: 470px;
        float: left;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
        .shell {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          display: none;
          .l_1 {
            font-size: 30px;
            color: #fff;
            text-align: center;
            margin-top: 180px;
          }
          .l_2 {
            width: 70%;
            font-size: 17px;
            color: #fff;
            margin: auto;
            margin-top: 20px;
            text-indent: 2em;
          }
        }

        &:hover .shell {
          display: block;
        }
      }
      .r {
        width: 355px;
        height: 230px;
        float: right;
        .r_1 {
          width: 100%;
          height: 230px;
          background: url("../../../assets/loginIndex/productionBaseR_1.png")
            center center no-repeat;
          background-size: 100% 100%;
        }
        .r_2 {
          width: 100%;
          height: 230px;
          margin-top: 10px;
          background: url("../../../assets/loginIndex/productionBaseR_2.png")
            center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .container-2 {
    width: 1168px;
    margin: 0 auto;
    padding-bottom: 60px;
    .m-40 {
      margin-top: 40px;
    }
    .m-16 {
      margin-top: 16px;
    }

    .title {
      margin-top: 60px;
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }

    .cont {
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        width: 378px;
        height: 146px;
        background: #ffffff;

        padding: 30px 20px;
        box-sizing: border-box;
        .img {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .t-1 {
          font-size: 20px;
          color: #3f4652;
          font-weight: 500;
        }
        .t-2 {
          font-size: 14px;
          color: #5c6b84;
          margin-top: 16px;
          font-weight: 400;
        }
      }
      .item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }

    .text-1 {
      font-size: 17px;
      color: #5c6b84;
      margin-top: 16px;
      text-align: center;
      padding: 0 75px;
    }

    .cont-2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 36px;

      .item {
        width: 180px;
        height: 299px;
        border-radius: 8rpx;
        overflow: hidden;

        .text {
          height: 100px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }
  }
}
